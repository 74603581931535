@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;1,6..12,1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  body {
    @apply text-main text-sm ; 
    font-family: 'Nunito', sans-serif;
  }
}